import { initializeApp } from "firebase/app";
import "firebase/messaging";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { postFCM } from "../api/fcm";
import { detectDevice } from "../common/detectDevice";
import { toast } from "react-toastify";
import { splitedBody } from "../common/helper";
import { ImgNotiWrapper, TextNotiWrapper } from "./firebase.styles";

// Firebase SDK Config Keys
const firebaseConfig = {
  apiKey: "AIzaSyDLoPsQbXb1mPrB84DrsDcpC6lOWM79NMU",
  authDomain: "caretstore.firebaseapp.com",
  projectId: "caretstore",
  storageBucket: "caretstore.appspot.com",
  messagingSenderId: "50947813618",
  appId: "1:50947813618:web:76b8592e6fb4b747e5a06b",
  measurementId: "G-CG3NEZ1P7T",
};

export const firebaseApp = initializeApp(firebaseConfig);
const messaging = async () =>
  (await isSupported()) && getMessaging(firebaseApp);

// FCM
export const getFCMToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BFjuPmB7sZ4Fkg3GMMvcqC0cT6x7RKtedbYlJ-IYQLmVHaRPvNTTt_p_nL7Lb9PoHeL2y5x9PfHwo-rnOYQQAgQ",
    });

    if (currentToken) {
      // 현재 토큰과 로컬스토리지에 저장된 토큰이 다를 때
      if (currentToken != localStorage.getItem("STORE_FCM_TOKEN")) {
        const userAgentData = detectDevice();

        const body = {
          modes: "FcmTokenInsertSet",
          token: currentToken,
          browser: userAgentData.browserName,
          division: userAgentData.deviceType,
        };

        await postFCM(body);

        localStorage.setItem("STORE_FCM_TOKEN", currentToken);
        return;
      } else console.error("It's already inserted token.");
    } else console.error("Empty token.");
  } catch (err) {
    if (err instanceof TypeError) {
      Notification.requestPermission().then((permission) => {
        if (permission !== "granted") return;
      });
    } else console.error("토큰을 가져오는 도중 에러가 발생하였습니다.", err);
  }

  onMessage(messaging, (payload) => {
    // 앱 실행 중인 상태에서 FCM 메시지를 수신할 때 실행되는 로직을 추가할 수 있습니다.
    try {
      if (Object.keys(payload.data).includes("image")) {
        // 푸시 알림용
        toast(
          () => (
            <ImgNotiWrapper href={payload.data.url} target="_blank">
              <div className="img-section">
                <img src={payload.data.image} />
              </div>
              <div className="contents-section">
                {payload.data.icon && (
                  <img className="icon" src={payload.data.icon} />
                )}
                <div>
                  <p className="fw-bold text-white">{payload.data.title}</p>
                  {splitedBody(payload.data.body)?.map((el, i) => (
                    <p key={i} className="text-white">
                      {el}
                    </p>
                  ))}
                </div>
              </div>
            </ImgNotiWrapper>
          ),
          fcmToastOpt(payload, true)
        );
      } else {
        // 일반 알림용
        toast(
          () => (
            <TextNotiWrapper>
              <p className="fw-bold text-white">{payload.data.title}</p>
              <p className="text-white">{payload.data.body}</p>
              <p className="text-white">{payload.data.time}</p>
            </TextNotiWrapper>
          ),
          fcmToastOpt(payload, false)
        );
      }
    } catch {}
  });
};

// toast options
export const fcmToastOpt = (payload, isPushAd) => {
  const defaultOpt = {
    type: toast.TYPE.SUCCESS,
    newestOnTop: true,
    position: "top-right",
    autoClose: false,
    [payload.data.icon && "icon"]: ({ theme, type }) => (
      <img src={payload.data.icon} />
    ),
  };

  let tempObj = {};

  if (isPushAd) {
    tempObj = {
      ...defaultOpt,
      theme: "custom-alert-push-ad",
      closeButton: true,
    };
    return tempObj;
  } else {
    tempObj = {
      ...defaultOpt,
      theme: "custom-alert-text",
      closeButton: false,
    };
    return tempObj;
  }
};
